@import url("https://fonts.googleapis.com/css2?family=Paytone+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Paytone One", sans-serif;
    font-weight: 400;
    font-style: normal;
}

table,
th,
td {
    @apply border border-l-0 border-r-0 border-solid border-[#E9ECF5];
}

th:first-child,
td:first-child {
    @apply pl-8;
}

th:last-child,
td:last-child {
    @apply pr-8;
}

th,
td {
    @apply text-sm py-3 text-[#777777];
}

table {
    @apply table-auto;
}

th {
    @apply uppercase text-[#123750CC] text-left font-normal;
}
